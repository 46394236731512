<template>
	<div v-if="showingCompletedMessage" class="mx-auto">
		<div class="flex flex-col items-center sm:px-44px">
			<img src="@/assets/images/logo.png" alt="Main Logo" class="lg:mt-8 lg:mb-10.75 sm:mb-62px lg:w-76 sm:w-453px p-4 m-0 cursor-pointer" @click="$router.push({ name: 'top' })" />
			<div class="p-4 w-50">
				<h3 class="py-4 mb-8 text-2xl font-bold lg:text-4xl sm:text-40px">お問い合わせ完了</h3>
				<div class="w-full lg:mt-10.75 sm:mt-28px border-3 border-asp-blue">
					<div class="lg:py-10.75 lg:px-8 p-4 lg:text-22px sm:text-30px">
						<p>お問合せ有難うございます。返信までにお時間を頂きます。</p>
					</div>
				</div>
			</div>
			<!-- <button
				class="lg:mt-9.5 sm:mt-62px lg:mb-11 sm:mb-60px focus:outline-none"
				type="button"
				@click="handleRedirectOnClickToBackButton"
			>
				<img
					class="w-300px"
					src="@/assets/images/button-back-login-screen.png"
					alt="Button Back To Login"
				/>
			</button> -->
		</div>
	</div>
	<div v-else class="flex-1">
		<div class="justify-center">
			<div class="mx-auto lg:w-76">
				<img src="@/assets/images/logo.png" alt="Main Logo" class="lg:mt-8 lg:mb-10.75 p-4  m-0 w-full cursor-pointer" @click="$router.push({ name: 'top' })" />
			</div>
			<div class="p-4 mx-auto lg:w-200">
				<div class="flex flex-1 text-lg font-medium">
					<div class="w-full">
						<Form v-slot="{ errors, values }" class="relative mt-6" :validation-schema="validationSchema" :initial-values="initialValues" @submit="handleSubmitContact">
							<div class="my-4 text-xl font-bold lg:my-8 lg:text-33px">
								お問い合わせフォーム
							</div>
							<div>
								<div class="mb-1">お名前</div>
								<Field name="name" as="input" type="text" maxlength="20" class="w-full px-4 py-2 border border-gray-600 focus:outline-none" />
								<div class="text-xs text-red-500 lg:text-base">
									{{ errors.name }}
								</div>
							</div>

							<div class="mt-4">
								<div class="mb-1">お名前（カタカナ）</div>
								<Field name="name_kana" as="input" type="text" maxlength="40" class="w-full px-4 py-2 border border-gray-600 focus:outline-none" />
								<div class="text-xs text-red-500 lg:text-base">
									{{ errors.name_kana }}
								</div>
							</div>

							<div class="mt-4">
								<div class="mb-1">電話番号</div>
								<Field name="phone_number" as="input" type="text" :maxlength="CONTACT.MAX_PHONE_NUMBER" class="w-full px-4 py-2 border border-gray-600 focus:outline-none" />
								<div class="text-xs text-red-500 lg:text-base">
									{{ errors.phone_number }}
								</div>
							</div>

							<div class="mt-4">
								<div class="mb-1">返信先のメールアドレス</div>
								<Field name="email" as="input" type="text" class="w-full px-4 py-2 border border-gray-600 focus:outline-none" />
								<div class="text-xs text-red-500 lg:text-base">
									{{ errors.email }}
								</div>
							</div>

							<div class="mt-4">
								<div class="mb-1">お問い合わせ内容</div>
								<Field name="content" as="textarea" type="text" :maxlength="CONTACT.MAX_CONTENT" class="w-full h-48 px-4 py-2 border border-gray-600 resize-none focus:outline-none" />
								<div class="text-xs  lg:text-base">{{ values.content?.length }} / {{ CONTACT.MAX_CONTENT }}</div>
							</div>

							<div class="mt-4 text-xs lg:text-base">
								<p class="mb-4">
									ご記入いただきました皆様の個人情報は、
									<router-link :to="{ name: 'policy' }" class="text-blue-500 underline" target="_blank">
										個人情報保護方針
									</router-link>
									に基づき取り扱わせていただきます。
								</p>
								<div>
									<input v-model="confirmedAgreed" type="checkbox" class="w-5 h-4 mr-2" />
									<span>
										上記の内容に同意される方はチェックをいれてを押してください。
									</span>
								</div>
								<div v-if="!firstRender && !confirmedAgreed" class="text-xs text-red-500 lg:text-base ">
									個人情報保護方針に同意してください。
								</div>
							</div>

							<div class="flex justify-between mt-5 mb-10 sm:justify-center">
								<button
									type="button"
									class="p-2 mr-4 text-base text-white rounded-full lg:px-8 lg:py-3 lg:mx-4 bg-asp-orange focus:outline-none sm:w-155px w-140px lg:text-xl"
									@click="handleRedirectOnClickToBackButton"
								>
									戻る
								</button>
								<button
									type="submit"
									class="p-2 text-base text-white rounded-full lg:px-8 lg:py-3 lg:mx-4 bg-asp-orange focus:outline-none sm:w-155px w-140px lg:text-xl"
									@click="firstRender = false"
								>
									送信する
								</button>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { markRaw } from 'vue';
import { Form, Field } from 'vee-validate';
import { object, string } from 'yup';
import firebase from 'firebase';
import 'firebase/firestore';
import { CONTACT } from '@/constants';
import { getFieldValue } from '@/apis/utils';
import mailApi from '@/apis/mailApi';
import { f } from '@/services/utils';

export default {
	name: 'FullPageHeader',
	components: {
		Form,
		Field
	},
	data() {
		const validationSchema = markRaw(
			object().shape({
				name: string()
					.max(20, 'お名前は、20文字以下にしてください。')
					.required('お名前を入力してください。'),
				name_kana: string()
					.max(40, 'フリガナは、40文字以下にしてください。')
					.required('フリガナを入力してください。')
					.matches(
						/^([゠ァアィイゥウェエォオカガキギクグケゲコゴサザシジスズセゼソゾタダチヂッツヅテデトドナニヌネノハバパヒビピフブプヘベペホボポマミムメモャヤュユョヨラリルレロヮワヰヱヲンヴヵヶヷヸヹヺ・ーヽヾヿ]+)$/u,
						'フリガナは全角カタカナで入力してください。'
					),
				phone_number: string()
					.max(CONTACT.MAX_PHONE_NUMBER, `電話番号は、${CONTACT.MAX_PHONE_NUMBER}文字以下にしてください。`)
					.matches(/^(?:\d{10}|\d{11})$/, '正しい電話番号を入力してください。')
					.required('電話番号を入力してください。'),
				content: string().required('お問い合わせ内容を入力してください。'),
				email: string()
					.required('返信先のメールアドレスを入力してください。')
					.email('正しいメールアドレスを入力してください。')
			})
		);
		// const validationSchema = markRaw(object().shape({}));

		return {
			validationSchema,
			handlingSubmitContact: false,
			showingCompletedMessage: false,
			confirmedAgreed: false,
			firstRender: true,
			initialValues: {
				email: '',
				name: '',
				name_kana: '',
				phone_number: '',
				content: ''
			},
			CONTACT
		};
	},
	methods: {
		async handleSubmitContact(values) {
			if (this.handlingSubmitContact || !this.confirmedAgreed) return;
			this.handlingSubmitContact = true;

			try {
				await firebase
					.firestore()
					.collection('d_contacts')
					.add({
						email: values.email,
						name: values.name,
						nameKana: values.name_kana,
						phoneNumber: values.phone_number,
						content: values.content,
						createdAt: getFieldValue().serverTimestamp()
					});

				mailApi.send({
					to: f(process.env.VUE_APP_MAIL_CONTACT),
					message: {
						subject: `${values.name}さんからお問い合わせが来ました`,
						html: `
						<p>お名前: ${values.name}</p>
						<p>お名前（カタカナ）: ${values.name_kana}</p>
						<p>電話番号: ${values.phone_number}</p>
						<p>返信先のメールアドレス: ${values.email}</p>
						<br />
						<p>お問い合わせ内容: </p>
							<div style="margin-left: 16px;"> ${values.content.replace(/\n/g, '<br />')} </div>
						`
					}
				});
				this.showingCompletedMessage = true;
			} catch (error) {
				console.log(error);
			} finally {
				this.handlingSubmitContact = false;
			}
		},
		handleRedirectOnClickToBackButton() {
			this.$router.push({ name: 'top' });
		}
	}
};
</script>
