import firebase from 'firebase/app';
import 'firebase/firestore';

const mailCol = firebase.firestore().collection('mail');

export default {
	/** @param {t.MailData} data */
	sendInvitation: async data => {
		return mailCol.add(data);
	},

	/** @param {t.MailData} data */
	async send(data) {
		return mailCol.add(data);
	}
};
